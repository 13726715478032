import React from 'react';
import { graphql } from 'gatsby';

import withMeta from '../hocs/withMeta';
import {
  IPageDetails,
  IPageContext,
  IPageAirportTypes,
  ITourDetails,
  ITransport,
} from '../interfaces';
import { AirportTourList, AirportHero } from '../components';
import { getPageElement } from '../utils';
import TransportFlybus from '../../src/BookingEngine/components/TransportFlybus';

interface IProps {
  // contentfulTour: ITourDetails;
  // transport: ITransport | null;
  // productYML: IDefaultPrices[] | null;
  // loadingYML: boolean;
  // currency: string;
  // pageContext: IPageContext;
}
interface IDefaultPrices {
  defaultPrice: number | null;
  discountPrice: number | null | undefined;
  id: number;
}
const AirportTransfer: React.FunctionComponent<IProps> = (
  {
    // contentfulTour,
    // transport,
    // productYML,
    // loadingYML,
    // currency,
    // pageContext,
  }
) => {
  //const { data } = props;
  //const { title, heroImage, pageElements } = data.contentfulPage;

  // const pageElem = getPageElement(
  //   pageElements,
  //   'ContentfulPageAirportTypes'
  // ) as IPageAirportTypes;

  return (
    <React.Fragment>
      {/* <AirportHero title={title} image={heroImage} pageContext={pageContext} />
      <div className='centered-content vertical-margin'>
        <AirportTourList
          flybus={pageElem.flybus}
          types={pageElem.otherTypes.filter(type => type && type.link)}
          langPath={pageContext.langPath}
        />
      </div> */}
    </React.Fragment>
  );
};

export const query = graphql`
  query AirportQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
  }
`;

export default AirportTransfer;
